import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import editor from "@/components/Editor";

import ViewUIPlus from "view-ui-plus";
import "view-ui-plus/dist/styles/viewuiplus.css";
import "./styles/index.less";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

const app = createApp(App);
app.use(store).use(router).use(ViewUIPlus).use(ElementPlus).mount("#app");
app.component("editor", editor);
