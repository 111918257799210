<template>
  <Sider hide-trigger>
    <Menu
      ref="menu"
      theme="dark"
      width="auto"
      :open-names="openedNames"
      :active-name="activeName"
      :accordion="true"
      @on-select="handleSelect"
    >
      <template v-for="item in menuList" :key="`menu-${item.name}`">
        <Submenu
          :name="item.name"
          v-if="item.children && item.children.length > 0"
        >
          <template #title>
            <Icon :type="item.meta.icon" v-if="!!item.meta.icon"></Icon>
            {{ item.meta.title }}
          </template>
          <MenuItem
            v-for="subItem in item.children"
            :key="`menu-${subItem.name}`"
            :name="subItem.name"
          >
            <Icon :type="subItem.meta.icon" v-if="!!subItem.meta.icon"></Icon>
            {{ subItem.meta.title }}
          </MenuItem>
        </Submenu>
        <MenuItem v-else :name="item.name">
          <Icon :type="item.meta.icon" v-if="!!item.meta.icon"></Icon>
          {{ item.meta.title }}
        </MenuItem>
      </template>
    </Menu>
  </Sider>
</template>
<script>
import { getUnion } from "@/libs/util";

export default {
  name: "siderMenuPage",
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    activeName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userName: null,
      openedNames: [],
    };
  },
  methods: {
    // 选择菜单
    handleSelect(name) {
      this.$emit("on-select", name);
    },
    getOpenedNamesByActiveName(name) {
      return this.$route.matched
        .map((item) => item.name)
        .filter((item) => item !== name);
    },
    updateOpenName(name) {
      if (name === "home") this.openedNames = [];
      else this.openedNames = this.getOpenedNamesByActiveName(name);
    },
  },
  mounted() {
    this.userName = sessionStorage.getItem("userName");

    this.openedNames = getUnion(
      this.openedNames,
      this.getOpenedNamesByActiveName(name)
    );
  },
  watch: {
    activeName(name) {
      this.openedNames = this.getOpenedNamesByActiveName(name);
    },
    openedNames() {
      this.$nextTick(() => {
        this.$refs.menu.updateOpened();
      });
    },
  },
};
</script>
<style scoped></style>
